import React from "react"
import Layout from "../components/layout"

export default function About() {
    return (
        <Layout>
           <h1>Tentang Web Blogku</h1>
            <p>
                Web Blogku dibangun dengan JAMStack berbasis Gatsby! Sangat menyenangkan menggunakannya.
                Banyak hal baru yang terus ditemukan dalam proses belajar dan mengembangkan Web Blogku ini.
            </p>
            <p>
                Pada Web Blogku ini saya akan menuliskan catatan, tutorial dan dokumentasi terkait dengan 
                pengembangan Web Blogku dan proses belajarku.
            </p>
        </Layout>
    )
}